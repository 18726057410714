'use strict';

async function getSomeData(url) {
  const getData = await fetch(url);
  return getData.json();
}

const buildPositionsList = data => {
  const sortedPositions = data.sort((a, b) => { a.order - b.order });
  const positionsContainer = document.getElementById('job-positions');

  const items = sortedPositions.map(position => {
    const positionName = (name, highlighted) => {
      let jobName = name.split(' ');
      {
        const highlightedIndex = jobName.indexOf(highlighted);
        jobName[highlightedIndex] = `
          <span class="c-positions__name c-positions__name--contrast">
            ${jobName[highlightedIndex]}
          </span>
        `
      }
      return jobName = jobName.join(' ');
    };
    return `
      <li class="c-positions__item">
        <article>
          <a href="${position.url}" class="c-positions__link">
            <div class="c-positions__info">
              <svg class="c-positions__icon">
                <use xlink:href="#${position.icon}"/>
              </svg>
              <div class="c-positions__name">
                ${positionName(position.name, position.highlighted)}
              </div>
            </div>
            <div class="c-positions__action">
              <div class="c-positions__btn">
                more
                <svg viewPort="0 0 20 11" width="20" height="11" class="c-positions__btn__arrow">
                  <use xlink:href="#tail-arrow"/>
                </svg>
              </div>
            </div>
          </a>
        </article>
      </li>
    `;
  }).join('');
  positionsContainer.innerHTML = `<ul class="c-positions">${items}</ul>`;
};

getSomeData('https://stage.enrian.com/api').then(function (data) {
  buildPositionsList(data);
});